const wrapper = {
    display: 'flex',
    height: '100%',
    background: 'black'
    // background: 'linear-gradient(#2a006e, #2a2929 65%)',
}

const center = {
    width: '100%',
    textAlign:'center',
    color: '#fff'
}

export { wrapper, center }