const technology = [
    "HTML",
    "Javascript",
    "Python",
    "CSS",
    "React",
    "Django",
    "Node.js",
    "Express",
    "MongoDB",
    "Mongoose",
    "GitHUB",
    "Git"
]

export default technology;